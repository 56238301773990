import { css } from '@emotion/react'
import theme from './../../../../gatsby-plugin-theme-ui'
import React, { useRef } from 'react'
import slugify from 'slugify'
import { mq } from '../../../../assets/media'
import { Slot } from '../../../../entity/slot'
import { useDetectDevice } from '../../../../hook/useDetectDevice'
import { tabValueJour, tabValueHeure, CellAllSegment } from '../../../atoms/Cell'
import { Container, ContainerMobile, InvisibleCell, StyledCell, Wrapper } from '../tableDayHoursSegment/TableDayHours.style'


export interface valueDayHours { jour: string, heure: string }
interface props {
    values: valueDayHours[],
    addOrRemoveValue: (value: Slot, remove: boolean) => void
}

export const TableDayHoursAllSegmentView = ({ values, addOrRemoveValue }: props) => {
    const isMobile = useDetectDevice()
    if(isMobile)
        return (
           <Wrapper css={css`    
            background-color: #ADD4D9;
            padding:0.5em;
            border-radius:15px;
            padding-bottom: 1.5em;
            `}>
            <ContainerMobile css={css`margin: 1em 0;`}>
                <Ordonnee values={values} />
                <Abscise values={values} />
                <InvisibleCell></InvisibleCell>
                {
                    tabValueJour.map((value, index) =>
                        <BuildLineMobile addOrRemoveValue={addOrRemoveValue} jour={value} key={"line" + value + index} />
                    )
                }
            </ContainerMobile>
        </Wrapper > 
        )
    else
        return (
        <Wrapper css={css`    
        background-color: #ADD4D9;
        padding:2em;
        border-radius:15px;
        `}>
            <Container >
                <Abscise values={values} />
                <Ordonnee values={values} />
                <InvisibleCell></InvisibleCell>
                {
                    tabValueHeure.map((value, index) =>
                        <BuildLine addOrRemoveValue={addOrRemoveValue} heure={value} key={"line" + value + index} />
                    )
                }
            </Container>
        </Wrapper >)
}

const Abscise = ({ values }) => {

    const isMobile = useDetectDevice()
    // todo mettre horaire
    return (<>{
        (isMobile ? tabValueHeure : tabValueJour).map((value, index) =>
            <TitleCell values={values} value={value} propName={isMobile? "heure" :"jour"}
                gridColumn={2 + index} gridRow={1} key={"abscise" + value + index}></TitleCell>
        )
    }

    </>)
}


const Ordonnee = ({ values }) => {
    const isMobile = useDetectDevice()

    return (<>{
        (isMobile ? tabValueJour : tabValueHeure).map((value, index) =>
            <TitleCell values={values} value={value} propName={isMobile ? "jour" :"heure"}
                gridColumn={1} gridRow={2 + index} key={"ordonnee" + value + index}></TitleCell>
        )
    }
    </>)
}


const BuildLine = ({ heure, addOrRemoveValue, }) => {

    return (
        <>
            {tabValueJour.map((item, index) => {
                const ref = useRef("Cell" + item + index)
                return (

                    <CellAllSegment innerRef={ref}
                        value={{ "heure": heure, "jour": item }}
                        addOrRemoveValue={addOrRemoveValue}
                        key={"Cell" + item + index} />)
            }
            )}
        </>
    )
}
const BuildLineMobile = ({ jour, addOrRemoveValue }) => {

    return (
        <>
            {tabValueHeure.map((item, index) => {
                const ref = useRef("Cell" + item + index)
                return (
                    <CellAllSegment innerRef={ref}
                        value={{ "jour": jour, "heure": item }}
                        addOrRemoveValue={addOrRemoveValue}
                        key={"Cell" + item + index} />)
            }
            )}
        </>
    )
}

interface propsTitleCell {
    values: valueDayHours[], value: string, propName: "jour" | "heure", gridColumn: number, gridRow: number
}

const TitleCell = ({ values, value, propName, gridColumn, gridRow }: propsTitleCell) => {


    const result = isValuePresent(values, propName, value)
    return (
        <div css={css`
        grid-column:${gridColumn};
        grid-row:${gridRow};
        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
        min-height:1.5em;
        `}>
            <StyledCell css={css`
            align-content: center;
            padding: 0 4px;
            border-radius: 5px;
            height: fit-content;
            width: 100%;
            height:100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height:1.5em;
            background-color: ${result ? "#f8e942;" : ""};
            color: ${theme.colors.text};
            box-shadow:none;
            ${mq[2]}{
                font-size: 14px;
            }
            `}>
                {value}
            </StyledCell >
        </div>
    )

}

const isValuePresent = (values: valueDayHours[] = [], propName: "jour" | "heure", valueItem: string) => {
    if (!values || !valueItem || values.length <= 0) return false

    const result: boolean = values.findIndex((item: valueDayHours) => item[propName] == slugify(valueItem, { lower: true })) > -1
    return result
}

interface PropsNameValue {
    jour: string
    heure: string
}

export interface valueDayHoursBySegment { segment: string, jour: string, heure: string }


export const getValuePresent = (values: valueDayHours[] = [], propNameValue: PropsNameValue): valueDayHoursBySegment[] => {

    const segments = values.reduce(function (previousValue, currentValue, currentIndex, array) {
        const isOneValueOfTheCell = (currentValue.heure == slugify(propNameValue.heure, { lower: true })) &&
            (currentValue.jour == slugify(propNameValue.jour, { lower: true }))

        if (isOneValueOfTheCell)
            previousValue.push(currentValue)

        return previousValue
    }, [] as valueDayHoursBySegment[])

    const result = segments.reduce((previousValue, currentValue, currentIndex, array) => {
        let item = {}
        item[currentValue.segment] = currentValue
        return { ...previousValue, ...item }
    }, {})
    return result
}